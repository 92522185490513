<template>
	<div class="container page">
		<div class="header">
			<van-nav-bar :title="$t('my.online_service')" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#fff" @click="back()" />
				</template>
			</van-nav-bar>
		</div>
		<div class="servicelistItem">
			<div class="servicelistItemTop">
				<img class="servicelistItemImage" src="img/mine/line.png">
				<div class="servicelistItemText">
					line
				</div>
				<div class="servicelistItemBtn" @click="toServicePage1()">
					<div class="servicelistItemBtnText">
						{{$t("my.contact")}}
					</div>
				</div>
			</div>

		</div>
		<div class="servicelistItem">
			<div class="servicelistItemTop">
				<img class="servicelistItemImage" src="img/mine/kakao.png">
				<div class="servicelistItemText">
					Kakao Talk
				</div>
				<div class="servicelistItemBtn" @click="toServicePage2()">
					<div class="servicelistItemBtnText">
{{$t("my.contact")}}
					</div>
				</div>
			</div>


		</div>
		<div class="servicelistItem">
			<div class="servicelistItemTop">
				<img class="servicelistItemImage" src="img/mine/tel.png">
				<div class="servicelistItemText">
					Telegram
				</div>
				<div class="servicelistItemBtn" @click="toServicePage()">
					<div class="servicelistItemBtnText">
						{{$t("my.contact")}}
					</div>
				</div>
			</div>
		
		</div>
	</div>


</template>

<script>
	export default {
		data() {
			return {

			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			toServicePage() {
				const service = this.$store.getters.getBaseInfo;
				console.log(service)
				if (service.iskefu == 1) {
					console.log('ssss')
					window.location.href = service.kefu
				}
				// this.$router.push("ServicePage");
			},
			toServicePage1() {
				const service = this.$store.getters.getBaseInfo;
				console.log(service)
				if (service.iskefu == 1) {
					console.log('ssss')
					window.location.href = service.kefu1
				}
				// this.$router.push("ServicePage");
			},
			toServicePage2() {
				const service = this.$store.getters.getBaseInfo;
				console.log(service)
				if (service.iskefu == 1) {
					console.log('ssss')
					window.location.href = service.kefu2
				}
				// this.$router.push("ServicePage");
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	.servicelistItem {
		display: flex;
		flex-direction: column;
		/* height: 200px; */
		padding: 30px 30px;
		margin: 30px 20px;
		border-radius: 20px;
		justify-content: space-between;
		background: #fff;
	}

	.servicelistItem .servicelistItemTop {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 100px;
	}

	.servicelistItem .servicelistItemTop .servicelistItemImage {
		width: 80px;
		height: 80px;
		border-radius: 50%;
	}

	.servicelistItem .servicelistItemTop .servicelistItemText {
		margin-left: 50px;
		font-size: 43px;
		font-weight: 700;
		color: #000;
		flex: 1;
	}

	.servicelistItem .servicelistItemTop .servicelistItemBtn {
		display: flex;
		width: 150px;
		height: 55px;
		border-radius: 30px;
		align-items: center;
		justify-content: center;
		background: linear-gradient(270deg, #e6c3a1, #7e5678);
	}

	.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText {
		color: #fff;
		font-size: 25px;
	}

	.servicelistItem .servicelistItemBottom {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		background: #f2f2f5;
		border-radius: 10px;
		color: #979799;
	}

	.servicelistItem .servicelistItemBottom .servicelistItemInfoText {
		font-size: 30px;
	}
</style>
