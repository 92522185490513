import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Language from '../pages/mine/Language.vue'/* 语言选择 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改性别 */
import Recharge from '../pages/mine/Recharge.vue'/* 充值 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改支付密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改登录密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo from '../pages/video/PlayVideo.vue'/* 视频播放页面 */
import Setbank from '../pages/mine/Setbank.vue'/* 填写收款卡 */
import BindCard from '../pages/mine/BindCard.vue'/* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw.vue'/* 提现 */
import Personalreport from '../pages/mine/Personalreport.vue'/* 个人报表 */
import GameRecord from '../pages/mine/GameRecord.vue'/* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord.vue'
import rechargerecode from '../pages/mine/rechargerecode.vue'
import heji from '../pages/mine/heji.vue'/* 提现记录 */

// 新页面
import Page1 from '../pages/choose/Page1.vue'/* 新页面1 */
import Page2 from '../pages/choose/Page2.vue'/* 新页面2 */
import Page3 from '../pages/choose/Page3.vue'/* 新页面3 */
import Page4 from '../pages/choose/Page4.vue'/* 新页面4 */
import Page5 from '../pages/choose/Page5.vue'/* 新页面5 */
import Page6 from '../pages/choose/Page6.vue'/* 新页面6 */
import Page7 from '../pages/choose/Page7.vue'/* 新页面7 */
import Page8 from '../pages/choose/Page8.vue'/* 新页面8 */
import Page9 from '../pages/choose/Page9.vue'/* 新页面9 */
import Page10 from '../pages/choose/Page10.vue'/* 新页面10 */
import Page11 from '../pages/choose/Page11.vue'/* 新页面11 */
import Page12 from '../pages/choose/Page12.vue'/* 新页面12 */
import Page13 from '../pages/choose/Page13.vue'/* 新页面13 */
import Page14 from '../pages/choose/Page14.vue'/* 新页面14 */
import Page15 from '../pages/choose/Page15.vue'/* 新页面12 */
import Page16 from '../pages/choose/Page16.vue'/* 新页面13 */
import Page17 from '../pages/choose/Page17.vue'/* 新页面14 */
import recharge1 from '../pages/mine/recharge1.vue'


Vue.use(VueRouter)
const routes = [
    { path: '/', redirect: '/Home', component: Home, meta: { title: '首页' } },
    { path: '/Home', name: 'home', component: Home, meta: { title: '首页' } },
    { path: '/Choose', name: 'choose', component: Choose, meta: { title: '选妃' } },
    { path: '/List', name: 'list', component: List, meta: { title: '选妃列表' } },
    { path: '/Profile', name: 'profile', component: Profile, meta: { title: '选妃详情' } },
    { path: '/Mine', name: 'mine', component: Mine, meta: { title: '我的' } },
    { path: '/Video', name: 'video', component: Video, meta: { title: '视频' } },
    { path: '/Game', name: 'game', component: Game, meta: { title: '游戏' } },
    { path: '/Login', name: 'login', component: Login, meta: { title: '登录' } },
    { path: '/Register', name: 'register', component: Register, meta: { title: '注册' } },
    { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { title: '在线客服' } },
    { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { title: '在线客服' } },
    { path: '/Setting', name: 'Setting', component: Setting, meta: { title: '设置' } },
    { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { title: '基本信息' } },
    { path: '/Setname', name: 'Setname', component: Setname, meta: { title: '修改真实姓名' } },
    { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { title: '修改性别' } },
    { path: '/Language', name: 'Language', component: Language, meta: { title: '语言选择' } },
    { path: '/Recharge', name: 'Recharge', component: Recharge, meta: { title: '充值' } },
    { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { title: '支付密码管理' } },
    { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { title: '登录密码管理' } },
    { path: '/Lottery', name: 'Lottery', component: Lottery, meta: { title: '详情页面' } },
    { path: '/Notice', name: 'Notice', component: Notice, meta: { title: '信息公告' } },
    { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { title: '视频播放' } },
    { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { title: '填写收款卡' } },
    { path: '/BindCard', name: 'BindCard', component: BindCard, meta: { title: '绑定银行卡' } },
    { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { title: '提现' } },
    { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { title: '个人报表' } },
    { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { title: '提现记录' } },
    { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { title: '任务记录' } },
	{ path: '/rechargerecode', name: 'rechargerecode', component: rechargerecode, meta: { title: '充值记录' } },
    { path: '/heji', name: 'heji', component: heji, meta: { title: '充提记录' } },
	{ path: '/recharge1', name: 'recharge1', component: recharge1, meta: { title: '充值' } },
    // 新路由
    { path: '/Page1', name: 'Page1', component: Page1, meta: { title: '吉隆坡' } },
    { path: '/Page2', name: 'Page2', component: Page2, meta: { title: '纳闽联邦直辖区' } },
    { path: '/Page3', name: 'Page3', component: Page3, meta: { title: '砂拉越州' } },
    { path: '/Page4', name: 'Page4', component: Page4, meta: { title: '登嘉楼州' } },
    { path: '/Page5', name: 'Page5', component: Page5, meta: { title: '雪兰莪州' } },
    { path: '/Page6', name: 'Page6', component: Page6, meta: { title: '玻璃市州' } },
    { path: '/Page7', name: 'Page7', component: Page7, meta: { title: '霹雳州' } },
    { path: '/Page8', name: 'Page8', component: Page8, meta: { title: '槟城州' } },
    { path: '/Page9', name: 'Page9', component: Page9, meta: { title: '彭亨州' } },
    { path: '/Page10', name: 'Page10', component: Page10, meta: { title: '森美兰州' } },
    { path: '/Page11', name: 'Page11', component: Page11, meta: { title: '马六甲州' } },
    { path: '/Page12', name: 'Page12', component: Page12, meta: { title: '吉兰丹州' } },
    { path: '/Page13', name: 'Page13', component: Page13, meta: { title: '吉打州' } },
    { path: '/Page14', name: 'Page14', component: Page14, meta: { title: '柔佛州' } },
	{ path: '/Page15', name: 'Page15', component: Page15, meta: { title: '15' } },
	{ path: '/Page16', name: 'Page16', component: Page16, meta: { title: '16' } },
	{ path: '/Page17', name: 'Page17', component: Page17, meta: { title: '17' } }
	
]
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
export default router
