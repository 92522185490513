<template>
	<div class="container page">
		<van-nav-bar class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
			<template #title>
				<span class="vod-title">充值</span>
			</template>
		</van-nav-bar>

		<!-- 块1 -->
		<div class="block" @click="showMessage">
			<span class="block-text">payment</span>
		</div>

		<!-- 块2 -->
		<div class="block" @click="showMessage">
			<span class="block-text">bank</span>
		</div>
	</div>
</template>

<script>
import { Toast } from 'vant'; // 导入 Vant Toast

export default {
	data() {
		return {
			vod_name: '充值'
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'Choose' });
		},
		showMessage() {
			Toast({
				message: '联系客服', // 弹窗显示的内容
				duration: 2000, // 持续时间，单位是毫秒，2秒后自动消失
				background: 'rgba(0, 0, 0, 0.5)', // 弹窗背景色
				className: 'custom-toast' // 添加自定义类名
			});
		}
	},
	created() {
		this.vod_name = this.$route.query.name;
	}
};
</script>

<style scoped>
.vod-title {
	max-width: 60%; /* 设置最大宽度 */
	margin: 0 auto; /* 自动水平居中 */
	color: #fff; /* 文字颜色 */
	font-size: 4.667vw; /* 字体大小 */
	line-height: 46px; /* 行高，确保文本在导航栏中垂直居中 */
	text-align: center; /* 文本居中 */
}

.block {
	width: 90%; /* 块宽度 */
	margin: 35px auto; /* 上下间距，左右居中 */
	padding: 20px; /* 块内边距 */
	background-color: #fff; /* 块的背景色 */
	border-radius: 0px; /* 圆角 */
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 阴影 */
	text-align: center; /* 文字居中 */
	cursor: pointer;
	background: linear-gradient(90deg, #f87171, #f87171);
}

.block-text {
	font-size: 24px; /* 文字大小 */
	color: white; /* 文字颜色 */
}

/* 自定义 Toast 的样式 */
.custom-toast {
	border-radius: 10px; /* 设置 Toast 的圆角 */
	padding: 10px; /* Toast 内边距 */
	font-size: 18px; /* Toast 字体大小 */
}

/* 可根据需要进一步调整样式 */
</style>
