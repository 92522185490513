<template>
	<div class="container page" style="background-color: #fbd9d9;">
		<van-nav-bar class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
			<template #title>
				<span class="vod-title">경상북도</span>
			</template>
		</van-nav-bar>

		<!-- 新增块 -->	
		<div class="image-block">
			<img class="image-item" src="/img/14/1.gif" alt="Image 1" />
			<img class="image-item" src="/img/14/2.gif" alt="Image 2" />
			<img class="image-item" src="/img/14/3.gif" alt="Image 4" />
            <img class="image-item" src="/img/14/4.gif" alt="Image 4" />
			<img class="image-item" src="/img/14/5.gif" alt="Image 5" />
			<img class="image-item" src="/img/14/6.webp" alt="Image 5" />
            <img class="image-item" src="/img/14/7.webp" alt="Image 4" />
			<img class="image-item" src="/img/14/8.webp" alt="Image 5" />
			<img class="image-item" src="/img/14/9.webp" alt="Image 5" />
            <img class="image-item" src="/img/14/10.webp" alt="Image 5" />
			<!-- <img class="image-item" src="/img/14/11.gif" alt="Image 5" />  -->
            <!-- <img class="image-item" src="/img/14/12.gif" alt="Image 5" /> -->
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			vod_name: '北京'
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'Choose' });
		}
	},
	created() {
		this.vod_name = this.$route.query.name;
	}
};
</script>

<style scoped>
.vod-title {
	max-width: 60%; /* 设置最大宽度 */
	margin: 0 auto; /* 自动水平居中 */
	color: #fff; /* 文字颜色 */
	font-size: 4.667vw; /* 字体大小 */
	line-height: 46px; /* 行高，确保文本在导航栏中垂直居中 */
	text-align: center; /* 文本居中 */
}

.image-block {
	display: flex; /* 使用flex布局 */
	flex-wrap: wrap; /* 允许换行 */
	justify-content: space-between; /* 使图片之间有间距 */
	/* margin: 10px; */ /* 上下左右间距10px */
	/* padding: 10px; */ /* 内边距10px */
	/* background-color: ; */ /* 可选：背景色 */
	border-radius: 8px; /* 可选：圆角 */
}

.image-item {
	width: 100%; /* 图片宽度为100% */
	margin-bottom: 10px; /* 每个图片底部间距 */
}

.image-item:nth-child(odd) {
	margin-right: 10px; /* 奇数项右侧间距 */
}

.image-item:nth-child(even) {
	margin-left: 10px; /* 偶数项左侧间距 */
}

/* 可根据需要进一步调整样式 */
</style>
