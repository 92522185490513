<template>
	<div class="container page">
		<van-nav-bar :title="$t('setting.fill_bank')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main-box">
			<div class="label">{{$t("setting.fill_bank_tip")}}</div>
			<van-cell-group>
				<van-field v-model="bank" :label="$t('setting.band_name')" readonly
					:placeholder="$t('setting.band_name_tip')" @click="showSelectBanks()" />
				<van-field v-model="bankid" :label="$t('setting.band_account')" type="digit"
					:placeholder="$t('setting.band_account_tip')" />
				<van-field v-model="username" :label="$t('setting.username')"
					:placeholder="$t('setting.username_place')" />
				<!-- <van-field v-model="mobile" :label="$t('setting.mobile')" type="digit"
					:placeholder="$t('setting.mobile_place')" /> -->
			</van-cell-group>
			<p>{{$t("setting.bank_warn")}}</p>
		</div>
		<van-button class="bindCard" type="default" @click="bindCard()">{{$t("setting.bank_ok")}}</van-button>
		<van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }">
			<div style="padding: 10px;">
				<div style="display: flex; justify-content: space-between; margin-top: 10px;">
					<van-button type="default" @click="onCancel" style="color: #999;font-size: 4.8vw;">
						{{$t('auth.cancel')}}
					</van-button>
					<van-button type="primary" @click="confirmSelection" style="color: #576b95;font-size: 4.8vw;">
						{{$t('auth.sure')}}</van-button>
				</div>
				<van-picker ref="bankPicker" :columns="banks" />

			</div>
		</van-popup>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				banks: [],
				showBank: false,
				userInfo: {},
				bankid: "",
				username: "",
				mobile: "",
				bank: "",
				bank_code: ""
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			bindCard() {
				if (this.bank === "" || this.bank === null || this.bank === undefined) {
					this.$toast.fail(this.$t("setting.band_name_tip"));
					return false;
				}

				if (this.username === "") {
					this.$toast(this.$t("setting.username_place"));
					return false;
				}

				// if (this.mobile === "") {
				// 	this.$toast(this.$t("setting.mobile_place"));
				// 	return false;
				// }

				this.$http({
					method: 'post',
					data: {
						bankid: this.bankid,
						bank: this.bank,
						bank_code: this.bank_code,
						username: this.username,
						mobile: this.mobile
					},
					url: 'user_set_bank'
				}).then(res => {
					if (res.code === 200) {
						this.$router.push({
							path: '/Mine'
						});
						this.$toast(res.msg);
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				});
			},
			showSelectBanks() {
				this.showBank = true;
			},
			confirmSelection() {
				const picker = this.$refs.bankPicker;
				const selectedValue = picker.getValues()[0]; // Get the selected value

				this.bank = selectedValue.text; // Update the bank name
				this.bank_code = selectedValue.value; // Update the bank code
				this.showBank = false; // Close the popup
			},
			onCancel() {
				this.showBank = false; // Close the popup
			},
			getUserInfo() {
				this.$http({
					method: 'get',
					url: 'user_info'
				}).then(res => {
					if (res.code === 200) {
						this.userInfo = res.data;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				});
			},
			getBankList() {
				this.$http({
					method: 'get',
					url: 'sys_get_banks'
				}).then(res => {
					if (res.code === 200) {
						this.banks = res.data;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				});
			},
			getUserBankInfo() {
				this.$http({
					method: 'get',
					url: 'user_get_bank'
				}).then(res => {
					if (res.code === 200) {
						this.is_bind = res.data.is_bank;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				});
			}
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				});
			} else {
				this.getUserInfo();
				this.getBankList();
				this.getUserBankInfo();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	.van-cell {
		font-size: 32px;
		line-height: 80px;
	}

	.van-hairline--bottom::after {
		border-bottom-width: 3px;
	}

	.bankbox {
		padding: 15px;
		color: #000;
		background-color: #fff;
	}

	.bankbox .title {
		padding: 8px 10px 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-size: 28px;
	}

	.main-box {
		background: #fff;

	}

	.main-box .label {
		padding: 20px;
		font-size: 35px;
		color: #797878;
	}

	::v-deep .van-picker__toolbar {
		height: 50px;
	}

	::v-deep .van-picker__cancel,
	.van-picker__confirm {
		padding: 0 20px;
		font-size: 20px;
	}

	::v-deep .van-picker-column {
		font-size: 40px;
	}

	.main-box p {
		padding: 0 20px;
		font-size: 30px;
		color: #ee0a24;
	}

	.bindCard {
		margin: 20px 30px 0;
		height: 80px;
		line-height: 1.22667rem;
		border-radius: 50px;
		color: #fff;
		font-size: 30px;
		font-weight: bolder;
		border: none;
		background: linear-gradient(90deg, #e6c3a1, #7e5678);
	}
</style>
