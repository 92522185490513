<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="navTitle" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
        <div v-else class="item_list" v-for="(v, key) in list" :key="key">
          <div class="topInfo">
            <span style="color: black;">{{ getTypeText(v.type) }}</span>
            <span :style="{ color: getTypeColor(v.type) }" style="font-size: 15px; margin-top: 10px; padding-top: 10px;">
              {{ $t('rechargerecode.je') }}：{{ v.money }}
            </span>
          </div>
          <div class="time">
            <span style="color: #656566; font-size: 4vw;">{{ v.update_time }}</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list: [],
      navTitle: ''  // 用于存储导航栏标题
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    onRefresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.getUserRechargeList(); // 刷新时重新获取数据
        this.isLoading = false;
      }, 500);
    },
    getUserRechargeList() {
      this.$http({
        method: 'get',
        url: 'heji'  // 接口地址
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data;
          // 设定导航栏标题为第一个记录的 type 值，如果有记录的话
          if (this.list.length > 0) {
            this.navTitle = this.$t('my.ctjl'); // 根据第一个记录的 type 值设置标题
          } else {
            this.navTitle = this.$t('my.ctjl'); // 默认标题
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getTypeText(type) {
      switch (type) {
       case 1: return this.$t('heji.recharge'); // i18n for recharge
               case 2: return this.$t('heji.withdraw_pending'); // i18n for pending withdrawal
               case 3: return this.$t('heji.withdraw_success'); // i18n for successful withdrawal
               case 4: return this.$t('heji.withdraw_failed');
        default: return '';
      }
    },
    getTypeColor(type) {
      switch (type) {
        case 1: return 'red'; // Red for recharge
        case 2: return '#FFA500'; // Yellow for pending
        case 3: return 'green'; // Green for successful withdrawal
        case 4: return '#ff253f'; // Red for failed withdrawal
        default: return '#000'; // Default color
      }
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' });
    } else {
      this.getUserRechargeList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}

.container .main {
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
}

.item_list {
  padding: 15px 15px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo {
  display: flex;
  justify-content: space-between;  /* 使记录类型和金额两端对齐 */
}

.item_list .topInfo span {
  font-size: 5.067vw;
}

.item_list .time span {
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
</style>
