<template>
  <div class="convention-hall page" :style="{ backgroundImage: 'url(/img/bj.png)', height: '100%', width: '100%' }">
    <van-nav-bar class="nav-bar" :title="$t('reservation.hall')">
      <template #left>
        <van-icon @click="goBack" name="arrow-left" class="back-arrow" />
      </template>
    </van-nav-bar>

    <div class="convention-item">
      <van-tabs animated sticky line-width="100px" :swipeable="true">
        <van-tab :title="$t('concubine.city')">
          <div class="card">{{ $t("concubine.city_tip") }}</div>
          <div class="address">
            <div class="address-row" v-for="(row, index) in addressRows" :key="index">
              <van-cell
                v-for="(item, idx) in row"
                :key="idx"
                class="address-cell"
                @click="addgo(item)"
                :style="{ justifyContent: row.length === 2 && idx === row.length - 1 ? 'flex-end' : 'center' }"
              >
                <div class="address-content">
                  <img :src="`/img/cs/${item.index + 1}.png`" alt="" class="address-image" />
                  <span class="address-text">{{ item.name }}</span>
                </div>
              </van-cell>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('concubine.price')">
          <div class="card">{{ $t("concubine.city_tip") }}</div>
          <div class="rig-box">
            <p class="rig-title">{{ $t("concubine.pri_resource") }}</p>
            <p class="rig-content">{{ $t("concubine.pri_obj") }}</p>
            <p class="rig-title">{{ $t("concubine.pri_service") }}</p>
            <p class="rig-content">{{ $t("concubine.pric_service_one") }}</p>
            <!-- <p class="rig-content">{{ $t("concubine.pric_service_two") }}</p> -->
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 固定15个地址数据
      addlist: [
        { id: 1, index: 0, name: "서울" },
        { id: 2, index: 1, name: "부산" },
        { id: 3, index: 2, name: "대구" },
        { id: 4, index: 3, name: "인천" },
        { id: 5, index: 4, name: "광주" },
        { id: 6, index: 5, name: "대전" },
        { id: 7, index: 6, name: "울산" },
        { id: 8, index: 7, name: "세종특별자치시" },
        { id: 9, index: 8, name: "제주도" },
        { id: 10, index: 9, name: "경기도" },
        { id: 11, index: 10, name: "강원도" },
        // { id: 12, index: 11, name: "충청북도" },
        { id: 13, index: 12, name: "충청남도" },
        { id: 14, index: 13, name: "전라북도" },
        { id: 15, index: 14, name: "경상북도" },
        { id: 16, index: 15, name: "경상남도" },
        // { id: 17, index: 16, name: "경상남도" }
      ],
    };
  },
  computed: {
    // 将地址列表分成每行最多三个元素的数组
    addressRows() {
      const rows = [];
      for (let i = 0; i < this.addlist.length; i += 3) {
        rows.push(this.addlist.slice(i, i + 3));
      }
      return rows;
    },
  },
  methods: {
    addgo(data) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' });
      } else {
        // 修改跳转链接
        this.$router.push({ path: `/Page${data.index + 1}` });
      }
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  position: relative; /* Remove absolute to ensure correct layout */
  background-color: #f2f2f5;
  overflow-y: auto; /* Enable vertical scrolling */
  padding-bottom: 60px;

}

::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
  color: rgb(194, 68, 145);
}

::v-deep .van-tabs__line {
  background-color: #7e5678;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}

.nav-bar {
  background: linear-gradient(90deg, #f87171, #f87171);
  height: 100px;
}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

.convention-hall {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allow flex-grow for proper spacing */
}

.card {
  background-color: rgba(232, 121, 249, 0.5);
  padding: 0.625rem;
  width: 95%;
  color: #fff;
  font-size: 0.8125rem;
  margin: 0.625rem auto;
  font-weight: 700;
}

.address {
  display: flex;
  flex-direction: column; /* 使地址块垂直排列 */
  margin: 0 auto;
  width: 95%;
}

.address-row {
  display: flex; /* 水平排列地址块 */
  justify-content: flex-start; /* 默认左对齐 */
  margin-bottom: 10px; /* 行与行之间的间距 */
}

.address-cell {
  flex: 1; /* 均分宽度 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  padding: 0; /* 去掉内边距 */
  margin: 0 5px; /* 地址块之间的间距 */
  background-color: transparent; /* 背景透明 */
}

.address-content {
  position: relative; /* 使文本相对位置 */
  width: 28vw; /* 设置块的宽度 */
  height: 28vw; /* 设置块的高度 */
}

.address-image {
  width: 100%; /* 图片宽度 */
  height: 100%; /* 图片高度 */
  object-fit: cover; /* 保持图片比例，裁剪多余部分 */
  border-radius: 10%; /* 可选：设置圆角 */
}

.address-text {
  position: absolute; /* 使文本绝对定位 */
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 调整位置使文本真正居中 */
  color: white; /* 文字颜色 */
  font-size: 4vw; /* 根据需要调整字体大小 */
  text-align: center; /* 文字居中对齐 */
  font-weight: bold;
  width: 100%;
}

.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}

.back-arrow {
  color: white;
}

.rig-title {
  color: #a21caf;
  font-size: 4.8vw;
  font-weight: 700;
}

.rig-content {
  font-size: 4vw;
  color: #000;
}
</style>
