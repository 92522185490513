<template>
	<div class="container page">
		<div class="header">
			<van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#fff" @click="back()" />
				</template>
				<template #right>
					<span @click="goToRechargeRecord"
						style="color: white; cursor: pointer; right: 0; margin-right: 10px;font-size: 4vw;">
						{{ $t("recharge.jilu") }}
					</span>
				</template>
			</van-nav-bar>

			<div class="info">
				<p class="title">
					{{ $t("recharge.curr_balance") }} ({{ $t("reservation.unit") }})
				</p>
				<p class="value">{{ this.balance }} {{ $t("recharge.yue") }}</p>
			</div>
			<div class="content recharge">
				<van-form @submit="onSubmit">
					<div class="form-item">
						<div class="form-item-title">{{ $t("recharge.input_money") }}</div>
						<div style="height: 65px">
							<van-field v-model="money" name="money" label=""
								:placeholder="$t('recharge.input_money')" />
						</div>
					</div>
					<div class="form-item">
						<div>
							<van-radio-group v-model="pay_way" style="display: none;">
								<van-radio name="Mexicopay">MexicoPay</van-radio>
								<van-radio name="2">OpplePay</van-radio>
							</van-radio-group>
						</div>
						<div style="margin: 16px">
							<van-button round block type="info" native-type="submit"
								style="background-color: rgb(248, 113, 113);">
								{{ $t("recharge.xiayibu") }}
							</van-button>
						</div>
					</div>
				</van-form>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from "vue";
	import {
		Form
	} from "vant";
	import {
		Field
	} from "vant";
	import {
		RadioGroup,
		Radio
	} from "vant";
	Vue.use(Form).use(Field).use(RadioGroup).use(Radio);
	export default {
		data() {
			return {
				balance: 0,
				pay_way: "Mexicopay",
				money: "",
			};
		},
		mounted() {
			this.balance = this.$route.params.balance;
		},
		methods: {
			back() {
				return window.history.back();
			},
			goToRechargeRecord() {
				this.$router.push({
					path: '/rechargerecode'
				});
			},
			//拉起支付
			onSubmit(values) {
				const money = values.money;
				if (money <= 0) {
					this.$toast(this.$t("reservation.money_err"));
					return;
				}
				this.$http({
					method: "post",
					data: {
						pay_way: this.pay_way,
						money: money,
					},
					url: "recharge",
				}).then((res) => {
					console.log(res);
					if (res.code === 200) {
						// 将支付信息传递到 recharge1 页面
						this.$router.push({
							path: '/recharge1',
							query: {
								pay_url: res.data.pay_url, // 这里假设需要传递支付链接
								money: money // 传递金额
							}
						});
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				});
			},
		},
		created() {
			if (!localStorage.getItem("token")) {
				this.$router.push({
					path: "/Login"
				});
			}
		},
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	.container .header {
		background: linear-gradient(90deg, rgb(248, 113, 113), rgb(248, 113, 113));
	}

	.recharge {
		padding: 10px 30px;
	}

	.van-cell {
		line-height: 65px !important;
	}

	.van-button {
		height: 87px !important;
	}

	.van-button__text {
		color: #fff !important;
	}

	/deep/.van-radio__icon {
		font-size: 30px !important;
	}

	/deep/.van-radio__label {
		margin-left: 25px !important;
		font-size: 35px !important;
		visibility: hidden;
	}

	/deep/.van-radio {
		height: 65px !important;
	}

	.form-item {
		margin-top: 40px;
	}

	.form-item-title {
		font-size: 36px;
		font-weight: bold;
		color: #999;
		margin-bottom: 20px;
	}

	.recharge span {
		font-size: 4vw;
		color: #868686;
		font-weight: 500;
	}

	.container .header .info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 20px;
		padding-top: 10px;
		margin: auto;
	}

	.container .header .info .title {
		font-size: 25px;
		color: #e5e5e5;
	}

	.container .header .info .value {
		margin: 10px auto;
		color: #fff;
		font-size: 50px;
		border-bottom: 1px solid #fff;
	}

	.container .header .info .tip {
		font-size: 30px;
		color: #e5e5e5;
	}

	.container .content {
		flex: 1;
		background: #f2f2f5;
	}
</style>
