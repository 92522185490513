<template>
	<div class="container page">
		<van-nav-bar :title="$t('setting.modify_real_name')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
			<template #right>
				<span class="nav-right" @click="save()">{{$t("setting.save")}}</span>
			</template>
		</van-nav-bar>
		<van-cell-group>
			<van-field v-model="name" :readonly="userInfo.name !== ''" :label="$t('setting.name')"
				:placeholder="$t('setting.name_place')" />
		</van-cell-group>
		<p>{{$t("setting.name_tip")}}</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				name: "",
				userInfo: {}
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			save() {
				if (this.userInfo.name) {
					this.$toast(this.$t("setting.repect"));
					return true;
				}
				if (this.name === "" || this.name === null || this.name === undefined) {
					this.$toast.fail(this.$t("setting.name_place"));
					return false;
				}
				this.$http({
					method: 'get',
					data: {
						name: this.name
					},
					url: 'user_set_name'
				}).then(res => {
					if (res.code === 200) {
						this.getUserInfo();
						this.name = this.userInfo.name;
						this.$toast(res.msg);
						this.$router.push({
							path: '/Setbank'
						});
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			getUserInfo() {
				this.$http({
					method: 'get',
					url: 'user_info'
				}).then(res => {
					if (res.code === 200) {
						this.userInfo = res.data;
						this.name = res.data.name;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			}
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserInfo();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	.van-cell {
		font-size: 35px;
		line-height: 80px;
	}

	.container p {
		padding: 0 15px;
		margin-top: 15px;
		font-size: 30px;
		color: #dc2037;
	}
</style>