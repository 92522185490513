<template>
  <div class="container page">
    <van-nav-bar :title="videoInfo.vod_name" class="nav-bar" style="background: linear-gradient(90deg, #f87171, #f87171);">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="movie-video">
      <video id="my-video" class="video-js"></video>
    </div>
    <div class="movie-content">
      <div class="movie-descript">
        <p>{{ videoInfo.vod_name }}</p>
        <span>{{ videoInfo.count }}{{ $t("video.num_play") }}</span>
      </div>

      <div class="movie-body">
        <div class="movie-title">
          <div>
            <span>{{ $t("index.recmonmand") }}</span>
          </div>
        </div>
        <div class="movie-list">
          <div class="movie-play-item" @click="toPlayVideo(v.id)" v-for="(v, key) in moreVideoInfo" :key="key">
            <div>
              <img :src="v.vod_pic" />
              <div>
                <div class="van-count-down">{{ v.time }}</div>
              </div>
            </div>
            <div>
              <p>{{ v.vod_name }}</p>
              <span>{{ v.count }}{{ $t("video.num_play") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  data() {
    return {
      nowPlayVideoUrl: "",
      cover: "",
      userInfo: {},
      videoInfo: {},
      moreVideoInfo: {},
      player: null,
      is_play: false,
      times: null,
      is_see: 0
    };
  },
  methods: {
    back() {
      this.$router.push({ path: 'Home' });
    },
    getVideoInfo() {
      this.$http({
        method: 'get',
        data: { id: this.$route.query.id },
        url: 'video_get_info'
      }).then(res => {
        this.videoInfo = res.data;
        this.nowPlayVideoUrl = this.videoInfo.vod_play_url;
        this.cover = this.videoInfo.vod_pic;
        let videos = document.getElementById('my-video');
        videos.poster = this.cover;
        this.getVideo();
      });
    },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' });
      } else {
        this.$router.push({ path: '?id=' + id });
        location.reload();
      }
    },
    getMoreVideoItem() {
      this.$http({
        method: 'get',
        url: 'video_get_more_item'
      }).then(res => {
        this.moreVideoInfo = res.data;
      });
    },
    getVideo() {
      this.player.src([{
        src: this.nowPlayVideoUrl,
        type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
      }]);
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.is_see = this.userInfo.is_see;

          // 检查用户状态
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t("video.account_out"));
            localStorage.clear();
            this.$router.push({ path: '/Login' });
          } else {
            // 检查是否有观看权限
            if (this.is_see === 1) {
              // this.$toast(`获取信息成功！您还有 ${this.userInfo.original_mfgk} 次免费观看次数。`);
              this.getVideoInfo();
              this.getMoreVideoItem();
            } else {
              // 没有观看权限，提示并跳转
              this.$toast(this.$t('video.buy'));
              this.$router.push({ path: '/Home' });
            }
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  mounted() {
    const _this = this;
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' });
    } else {
      this.player = videojs("my-video", {
        height: "200px",
        preload: "auto",
        controls: true,
        multipleArray: [0.75, 1, 1.5, 2],
      }, function() {
        this.on("play", () => {
          _this.is_play = true;
        });
      });
  
      this.getUserInfo();
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose(); // 销毁播放器实例
      this.player = null; // 清空引用
    }
  },

  destroyed() {
    if (this.is_play) {
      this.is_play = false;
    }
    clearInterval(this.times);
  }
};
</script>



<style scoped>
.video-js {
  width: 100%;
  font-size: 24px;
}
.movie-content {
  flex: 1;
  overflow-y: auto;
}
.movie-content .movie-descript {
  width: 100%;
  height: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}
.movie-content .movie-descript p {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
.movie-content .movie-descript span {
  color: #979799;
}
.movie-content .movie-body {
  width: calc(100% - 20px);
  margin: 0 auto;
}
::v-deep .movie-video .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}
.movie-content .movie-body .movie-title {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie-content .movie-body .movie-title > div:first-child {
  width: 410px;
}
.movie-content .movie-body .movie-title > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
.movie-content .movie-body .movie-title > div:first-child span:before {
  content: "";
  display: block;
  width: 8px;
  height: 30px;
  background-color: #f87171;
  border-radius: 25px;
  margin-right: 10px;
}
.movie-play-item {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  display: flex;
  background-color: #fff;
  margin-bottom: 20px;
}
.movie-play-item > div {
  height: 100%;
}
.movie-play-item > div:first-child {
  width: 200px;
  position: relative;
}
.movie-play-item > div:first-child > img {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.movie-play-item > div:first-child > div {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, .4);
  border-radius: 0 0 0 10px;
}
.movie-play-item > div:first-child > div .van-count-down {
  color: #fff;
  font-size: 25px;
}
.movie-play-item > div:nth-child(2) p {
  width: 500px;
  height: 60px;
  font-size: 30px;
  line-height: 32px;
  overflow: hidden;
}
.movie-play-item > div:nth-child(2) span {
  color: #979799;
}
</style>
