<template>
	<div class="container page">
		<van-nav-bar :title="$t('my.task_record')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
				<van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
				<div v-else class="item_list" v-for="(v,key) in list" :key="key">
					<div class="lottery_info">
						<van-image class="cover" :src="v.ico">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>

						<!-- 移动后的 topInfo -->


						<span class="period-number">{{v.expect}}</span>
						<span class="period-number">{{v.cpname}}</span>
						<span class="period-number">{{v.name}}</span>
					</div>

					<div class="recent">
						<div class="kuaisan-ball left">
							<span class="period-number1" style="float: left;">{{v.type}}</span>
							<!-- <span class="period-number1" style="float: left;">{{v.profit}}</span> -->
							<!-- <span class="res-des middle">{{ displayOpencode }}</span>
              <span class="res-des middle">{{ displayReservation }}</span>
              <span class="res-des middle">{{ displayParity }}</span> -->
						</div>
					</div>

					<div class="topInfo">

						<span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
						<span v-else>{{v.status_text}}</span>

						<span>{{$t("reservation.money")}}：{{v.money}}</span>
						<span style="float: right;" :style="{ color: v.profit > 0 ? 'red' : 'green' }">
							{{$t("reservation.profit")}}：{{ v.profit }}
						</span>
						

						<!-- <span>{{v.id}}</span> -->
					</div>
					<div class="time">
						<span>{{$t("reservation.order_time")}}：{{v.create_time}}</span>
					</div>
					<div class="time">
						<span>{{$t("reservation.settle_time")}}：{{v.update_time}}</span>
					</div>
					<!-- <span style="float: right;" :style="{ color: v.profit > 0 ? 'green' : 'red' }">
						{{ v.cpname }}
					</span> -->
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>


<script>
	export default {
		data() {
			return {
				isLoading: false,
				list: [],
			};
		},
		computed: {
			displayOpencode() {
				// 确保 list 中有数据，并且 v 是当前需要的游戏对象
				const v = this.list[0]; // 假设你要显示第一个游戏的 opencode
				return v && v.status === 0 ? 0 : (v ? v.opencode[0] + v.opencode[1] + v.opencode[2] : 0);
			},
			displayReservation() {
				const sum = this.displayOpencode;
				const v = this.list[0]; // 假设你要显示第一个游戏的状态
				return v && v.status === 0 ?
					0 :
					(sum >= 11 && sum <= 18) ?
					this.$t("reservation.big") :
					this.$t("reservation.small");
			},
			displayParity() {
				const v = this.list[0]; // 假设你要显示第一个游戏的状态
				return v && v.status === 0 ?
					0 :
					(this.displayOpencode % 2 === 0) ?
					this.$t("reservation.double") :
					this.$t("reservation.single");
			},
		},
		methods: {
			back() {
				return window.history.back();
			},
			onRefresh() {
				this.isLoading = true;
				setTimeout(() => {
					this.$toast(this.$t("reservation.refresh"));
					this.isLoading = false;
				}, 500);
			},
			getUserGameList() {
				this.$http({
					method: 'get',
					url: 'user_get_game_list',
				}).then(res => {
					if (res.code === 200) {
						console.log(res.data);
						this.list = res.data; // 更新游戏列表
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				});
			},
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				});
			} else {
				this.getUserGameList();
			}
		},
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}

	::v-deep .van-loading__text {
		color: #000000;
		font-size: 35px;
	}

	.container .main {
		position: relative;
		overflow: auto;
		background-color: #f2f2f5;
		height: 100%;
		padding: 0 10px;
	}

	.item_list {
		padding: 15px 15px;
		margin: 30px 10px;
		background: #fff;
		border-radius: 10px;
		line-height: 60px;
	}

	.item_list .topInfo span {
		flex: 1;
		font-size: 3.33vw;
		font-weight: 700;
		color: #ff253f;
	}

	.item_list .time span {
		flex: 1;
		font-size: 25px;
		font-weight: 500;
		color: #000;
	}

	.item_list .topInfo span:last-child {
		/* float: right; */
	}

	.item_list .desc span {
		font-size: 25px;
		font-weight: 700;
		color: #9b9b9b;
	}

	.item_list .cover {
		width: 60px;
		height: 60px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.item_list .period-number {
		margin-left: 50px;
		margin-right: 10px;
		height: 50px;
		line-height: 60px;
		font-size: 35px;
		font-weight: 700;
		color: #000;
	}

	.item_list .period-number1 {
		/* margin-left: 50px; */
		margin-right: 10px;
		height: 50px;
		line-height: 60px;
		font-size: 35px;
		font-weight: 700;
		color: #000;
	}

	.item_list .lottery_info {
		display: flex;
	}

	.recent {
		display: flex;
		align-items: center;
		height: 100px;
		margin-right: 2vw;
		font-size: 4.667vw;

	}

	.kuaisan-ball .left {
		justify-content: flex-start;
	}

	.kuaisan-ball {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.kuaisan-ball .res-img {
		width: 70px;
		height: 70px;
		margin-right: 30px;
	}

	.kuaisan-ball .res-des {
		font-weight: 700;
		text-align: center;
		color: #000;
	}

	.kuaisan-ball .res-des.middle {
		width: 15%;
		font-size: 35px;
	}
</style>
